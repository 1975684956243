<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="teachersStore.teacher.d_nameLast + ', ' +
      teachersStore.teacher.d_nameFirst" />

      <!--Function Bar-->
      <teacher-function-bar />

      <!--Classrooms List-->
      <v-container fluid class="pa-0">
        <v-row dense>
           <v-col cols="12" sm="12" md="9">
             <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-bus-school</v-icon>
                <v-toolbar-title class="pl-2">Classroom History</v-toolbar-title>
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5">
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" sm="12">
                    <classroom-list/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <notes :key="teachersStore.teacher.id" :noteSection="6" />
          </v-col>

        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import TeacherFunctionBar from '@/components/TeacherFunctionBar';
import ClassroomList from '@/components/TeacherClassroomList';
import Notes from '@/components/Notes';

export default {
  name: 'TeacherClassrooms',
  mixins: [mixin],
  computed: {
    ...mapState({
      teachersStore: (state) => state.teachersStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
	  TitleBar,
    TeacherFunctionBar,
    ClassroomList,
    Notes
  },
  created() {
    this.initialize();
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
		  await this.$store.dispatch('teachersStore/read', this.$route.params.teacherUUID);
		  await this.loadValueLists();
      this.$store.dispatch('progressStore/set', false);
	  },

    async loadValueLists() {
      const data = {
        id: [34]
      };
      await this.$store.dispatch("valueListsStore/items", data);
    }
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
