<template>
  <div>

    <!--Table-->
    <v-simple-table
      v-if="teachersStore.teacher.classroom_history && teachersStore.teacher.classroom_history.length > 0"
      :fixed-header="true"
      class="py-0 my-0 blue-grey lighten-5"
    >
      <template #default>
        <thead>
          <tr>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">Provider</th>
            <th class="text-center grey--text text--darken-1 blue-grey lighten-5">Classroom</th>
            <th class="text-center grey--text text--darken-1 blue-grey lighten-5">Classroom Role</th>
            <th class="text-center grey--text text--darken-1 blue-grey lighten-5">BK License</th>
            <th class="text-center grey--text text--darken-1 blue-grey lighten-5">Start Date</th>
            <th class="text-center grey--text text--darken-1 blue-grey lighten-5">End Date</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in teachersStore.teacher.classroom_history" :key="item.id">
          <tr v-bind:style="index > 0 ? 'border-top: solid thin #dae1e7;' : ''">
            <td style="border:none" class="text-left" :class="'light-blue--text text--darken-4'">
              {{ item.classroom.provider ? item.classroom.provider.d_display : '' }}</td>
            <td style="border:none" class="text-center" :class="'light-blue--text text--darken-4'">{{ item.classroom.d_name }}</td>
            <td style="border:none" class="text-center" :class="'light-blue--text text--darken-4'">
              {{ valueListsStore.valueListItems[34].find(arr => arr.d_sort == item.f_role)['d_name'] }}</td>
              <td style="border:none" class="text-center" :class="'light-blue--text text--darken-4'">{{ $_yesNo(item.f_bk) }}</td>
              <td style="border:none" class="text-center" :class="'light-blue--text text--darken-4'">{{ $_formatDate(item.d_dateStart) }}</td>
            <td style="border:none" class="text-center" :class="'light-blue--text text--darken-4'">{{ $_formatDate(item.d_dateEnd)}}</td>
          </tr>
          <tr>
            <td colspan="6" style="vertical-align: text-top; height:5px"><span class="text-caption grey--text"
                >Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
                Modified: {{ $_formatTimestamp(item.updated_at) }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-list v-else class="py-0 blue-grey lighten-5">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="error--text text-subtitle-2 text-xs-center">No Classroom Records
            Exist</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'TeacherClassroomList',
  mixins: [mixin],
  computed: {
    ...mapState({
      teachersStore: (state) => state.teachersStore,
      valueListsStore: state => state.valueListsStore
    }),
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
